import { fetchPost, fetchGet, fetchGetMess } from "../index";

//获取概览表格数据
export const getOverviewData = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sipo/criteriaquery", params);
};
//单车拒绝
export const sipoVlReject = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/sipo/reject", { params });
};
//单车发布
export const sipoVlRelease = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/sipo/released", { params });
};
//批量发布
export const sipoBatchRelease = (
  effectiveAppealDays: string,
  params = {}
): Promise<any> => {
  return fetchPost(
    "/rvapi/vl/sipo/batchReleased?effectiveAppealDays=" + effectiveAppealDays,
    params
  );
};
//批量拒绝
export const sipoBatchReject = (
  effectiveAppealDays: string,
  params = {}
): Promise<any> => {
  return fetchPost(
    "/rvapi/vl/sipo/batchReject?effectiveAppealDays=" + effectiveAppealDays,
    params
  );
};
//省市联调
export const getProviceItem = (): Promise<any> => {
  return fetchGetMess("/rvapi/basic/data/provinceCity");
};
//省市联调
export const getCityItem = (prov: ""): Promise<any> => {
  return fetchGetMess(`/rvapi/basic/data/provinceCity?prov=${prov}`);
};
//获取batch opertion提示信息参数
export const getBatchOpertionMsg = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sipo/getBatchOpertionMsg", params);
};
