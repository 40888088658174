export interface QueryListType {
  retailPeriod: string[];
  brand?: string | null;
  vinNo: string | null;
  vinNos: string[];
  usage: string | null;
  submitStatus: string | null;
  registrationPeriod: string[];
  submissionPeriod: string[];
}

export interface SearchListType {
  retailStartDate: string | null;
  retailEndDate: string | null;
  brand?: string | null;
  vinNo: string | null;
  vinNos: string[];
  usage: string | null;
  submitStatus: string | null;
  registrationStartDate: string | null;
  registrationEndDate: string | null;
  submissionStartDate: string | null;
  submissionEndDate: string | null;
  current: number | 0;
  size: number | 20;
}

export const checkSubmitStatus = {
  '': '未上传',
  '0000': '未上传',
  '1111': '已上传未提交',
  '2222': '已提交'
};

export const EnumCheckStatus = {
  '0000': '无',
  '1111': '系统检查中',
  '1112': '待审核',
  '1113': '修改待审核',
  '2222': '有效',
  '3333': '无效'
};

export interface SICCOCheckingQueryListType {
  brand?: string | null;
  vinNo: string | null;
  vinNos: string[];
  specialUsage: string | null;
  retailType: string | null;
  restrictedCity: string | null;
  isSampled: string | null;
  submitMode: string | null;
  checkingResult: string | null;
  dealerName: string | null;
  dealerCode: string | null;
  swtCode: string | null;
  checkingStatus?: Array<string>;
  isAppeal?: 'Y' | 'N';
}

export interface SICCOCheckingSearchListType {
  brand?: string | null;
  brandList: string[];
  vinNo: string | null;
  vinNos: string[];
  specialUsage: string | null;
  retailType: string | null;
  restrictedCity: string | null;
  retailStartDate: string | null;
  retailEndDate: string | null;
  registrationStartDate: string | null;
  registrationEndDate: string | null;
  submissionStartDate: string | null;
  submissionEndDate: string | null;
  isSampled: string | null;
  submitMode: string | null;
  checkingResult: string | null;
  dealerName: string | null;
  dealerCode: string | null;
  swtCode: string | null;
  licenceCheckStatus?: Array<string>;
  isAppeal?: 'Y' | 'N';
}

export interface ManualParamsQueryListType {
  vinNo: string | null;
  vinNos: string[];
  retailDatePeriod: string[];
  region: string | null;
  dealerCode: string | null;
  isModified: string | null;
  submitMode: string | null;
}

export interface ManualParamsSearchListType {
  vinNo: string | null;
  vinNos: string[];
  retailStartDate: string | null;
  retailEndDate: string | null;
  region: string | null;
  dealerCode: string | null;
  isModified: string | null;
  submitMode: string | null;
}

export const isPrivateStatue = {
  '0': '私有',
  '1': '公有'
};

export interface CommentType {
  id: number | 0;
  comment: string | null;
}

export interface CheckingResultSearchType {
  retailDate: string[];
  bu: string | null;
  registrationPeriod: string[];
  submissionPeriod: string[];
  checkResult: string | null;
}

export interface CheckingResultSearchListType {
  retailStartDate: string | null;
  retailEndDate: string | null;
  bu: string | null;
  registrationStartDate: string | null;
  registrationEndDate: string | null;
  submissionStartDate: string | null;
  submissionEndDate: string | null;
  checkResult: string | null;
  current: number | 0;
  size: number | 20;
  vinNo?: string;
  vinList?: string[];
}

export interface SelectArrayType {
  text: string | null;
  value: string | null;
}
